import styled from "@emotion/styled"
import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"

export const SharedDivider = styled.div`
  width: 100%;
  height: 5em;

  @media (max-width: 1300px) {
    height: 3.75em;
  }
  @media (max-width: 900px) {
    height: 2.5em;
  }

  min-height: 25px;

  &[data-count="2"] {
    height: 10em;
    @media (max-width: 1300px) {
      height: 7.5em;
    }
    @media (max-width: 900px) {
      height: 5em;
    }

    min-height: 50px;
  }

  &[data-count="3"] {
    height: 15em;
    @media (max-width: 1300px) {
      height: 11.25em;
    }
    @media (max-width: 900px) {
      height: 7.5em;
    }

    min-height: 75px;
  }

  &[data-count="4"] {
    height: 20em;
    @media (max-width: 1300px) {
      height: 15em;
    }
    @media (max-width: 900px) {
      height: 10em;
    }

    min-height: 100px;
  }

  &[data-count="5"] {
    height: 25em;

    @media (max-width: 1300px) {
      height: 18.75em;
    }
    @media (max-width: 900px) {
      height: 12.5em;
    }

    min-height: 125px;
  }
`

export const SharedDividerHalf = styled.div`
  width: 100%;
  height: 2.5em;
  @media (max-width: 1300px) {
    height: 1.875em;
  }
  @media (max-width: 900px) {
    height: 1.25em;
  }

  min-height: 25px;
`

export const SharedH3 = styled.h3`
  margin: 0 auto;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 3.75em;
  line-height: 1.322;
  text-align: center;
  color: #111;
  padding: 0 1.25em;
  max-width: 37em;

  @media (max-width: 640px) {
    max-width: calc(100vw - 50px);
    padding: 0;
  }
`

export const SharedH3Description = styled.p`
  margin: 0 auto;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 2.0625em;
  text-align: center;
  color: #333;
  padding: 0 1.25em;
  max-width: 50em;

  @media (max-width: 640px) {
    max-width: calc(100vw - 50px);
    padding: 0;
  }
`

export const orangeButtonColors = css`
  color: #fff;
  background-color: #fd7114;
  :hover {
    background-color: #ea640c;
  }
  :active {
    background-color: #ff6600;
  }
`

export const Input1 = styled.input`
  background-color: #fff;
  color: ${(props) => (props.hasValue ? "#111" : "#525151")};
  border: ${(props) =>
    props.notValid
      ? "1px solid #F5222D"
      : props.hasValue
      ? "1px solid #525151"
      : "1px solid #E1E2E3"};
  border-radius: 6px;
  outline: none;
  padding: 10px 16px;
  font-size: 1.5em;
  border-radius: 6px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  width: 100%;

  :hover {
    border: 1px solid #ea640c;
    box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
  }

  :focus {
    border: 1px solid #ea640c;
    box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
  }
`

export const orangeButtonColorsTransparentBg = css`
  background-color: #fff;
  color: #fd7114;
  border: 1px solid #fd7114;

  :hover {
    color: #ea640c;
    border: 1px solid #ea640c;
  }
  :active {
    color: #ff6600;
    border: 1px solid #ff6600;
  }
`

const css1 = css`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  color: #fff;
  background-color: #fd7114;
  outline: none;
  border-radius: 6px;
  width: max-content;
  font-size: 1.6em;
  padding: 0.6em 1.2em;
  text-decoration: none;
  cursor: pointer;
  border: 0;
`

export const SharedOrangeButton = styled(Link)`
  ${css1};
`

export const SharedOrangeButtonHref = styled.a`
  ${css1};
  ${orangeButtonColors};

  :disabled,
  [disabled] {
    background: #8f96a2 !important;
    cursor: disabled !important;
    pointer-events: none;
  }

  cursor: ${(props) => (props.isLoading ? "waiting !important;" : "pointer")};
`

export const SharedH3DescriptionMini = styled.p`
  margin: 0 auto;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 0 1.25em;
  max-width: 50em;
  color: #525151;
  margin-top: 0.5em;
  font-size: 1.4em;

  @media (max-width: 640px) {
    max-width: calc(100vw - 50px);
    padding: 0;
    font-size: 1.8em;
  }
`

export const SharedModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  z-index: 1000;
  max-width: 100%;
  left: 0;
  top: 0;
`
